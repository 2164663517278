import { environment } from "src/environments/environment";

export const ApiUrls = {
  loginApi: environment.identityApiRoot + 'authorize/customer/login',
  ipAddress: environment.usersApiRoot + 'common/ipaddress',
  customerDetails: environment.usersApiRoot + 'customer/details',
  refreshToken: environment.identityApiRoot + 'authorize/customer/refreshToken',
  resetPassword: (userName: string) => environment.identityApiRoot + `authorize/customer/${userName}/forgotpassword/request`,
  resetCustomerPassword: environment.identityApiRoot + `authorize/customer/resetpassword`,
  resetPasswordOTPValidate: (userName: string, otp: string) => environment.identityApiRoot + `authorize/customer/${userName}/forgotpassword/${otp}/validate`,
  completeResetPassword: environment.identityApiRoot + `authorize/customer/forgotpassword`,
  registerCustomer: environment.usersApiRoot + 'customer/register',
  verifyEmail: (email : string) => environment.identityApiRoot + `authorize/customer/${email}/send-otp`,
  submitCustomerRegistrationOTP: (userId: string, otp: string) => environment.identityApiRoot + `authorize/customer/${userId}/confirm/${otp}`,
  phoneNumberExistsCheck: (phoneNumber: string) => environment.identityApiRoot + `authorize/customer/${phoneNumber}/exists`,
  emailExistsCheck: (email: string) => environment.identityApiRoot + `authorize/customer-email/${email}/exists`,
  updateCustomerPhone: (phone: string) => environment.usersApiRoot + `customer/phone/${phone}`,

  // Product related apis
  getAllDressCategories: environment.ordersApiRoot + `product/dress-category`,
  getAllProducts: environment.ordersApiRoot + `product`,
  getAllFabricTypes: environment.ordersApiRoot + `fabric-types`,
  getAllFabricColors: environment.ordersApiRoot + `fabric-colors`,
  getAllFabricPatterns: environment.ordersApiRoot + `fabric-patterns`,
  addToWishList: (productId: number) => environment.ordersApiRoot + `customer-product/wishlist/${productId}/add`,
  removeFromWishlist: (productId: number) => environment.ordersApiRoot + `customer-product/wishlist/${productId}/remove`,

  // Quote Related
  getProductMeasurements : environment.ordersApiRoot +  `measurements`,
  getCustomerAddresses : environment.usersApiRoot +  `customer-address`,
  createNewQuote : environment.ordersApiRoot + `customer-quote/request-quote`,
  getAllQuotes: environment.ordersApiRoot + `customer-quote`,
  getOneQuoteDetails: (quoteId: number) => environment.ordersApiRoot + `customer-quote/quote/${quoteId}`,
  createQuote : environment.ordersApiRoot + 'customer-quote/create-order',
  cancelQuote :(quoteId: number)  => environment.ordersApiRoot + `customer-quote/cancel-order/${quoteId}`,
  acceptAndPay : (quoteId : number, status : boolean) =>  environment.ordersApiRoot + `customer-quote/quote/${quoteId}/payment-status/${status}`,

  // Wardrobe
  getAllWardrobe: environment.ordersApiRoot + `wardrobe`,
  getCategoryMeasurements: (dressCategoryId: number) => environment.ordersApiRoot + `category-measurements/${dressCategoryId}`,
  manageWardrobe: environment.ordersApiRoot + `wardrobe/`,
  getWardrobeByProduct: (productId: number, measurementCategory: any) => environment.ordersApiRoot + `wardrobe/product/${productId}?filter=["measurementCategory", "=", ${measurementCategory}]`,
  validateWardrobe: (wardrobeName: string, measruementCategory: number, wardrobeId = 0) => environment.ordersApiRoot + `wardrobe/${wardrobeName}/exists?wardrobeId=${wardrobeId}&measurementCategory=${measruementCategory}`,

  getPincodeDetails : (pincode: string) => environment.usersApiRoot + `common/zipcode/${pincode}`,

  getAboutUsHeadings: environment.usersApiRoot + `api/configure-screen/categories`,
  getAboutUsScreen: environment.usersApiRoot + `api/configure-screen/`,
}
